<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\modal-page-worktype.vue
  FILE_NAME : appointment-selection-worktype
  AUTHOR : bykim
  DATE : 2024-01-18
  DESCRIPTION : 근무스케줄 근무유형 그리드 페이지
-->
<template>
  <div class="container">
    <div :hidden="hiddenHeader">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 140px" />
          <col style="width: auto" />
          <col style="width: 140px" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">
              <label for="label5">상담원</label>
            </th>
            <td colspan="1" class="clearfix">
              {{ agentState.agtNm }}
            </td>
            <th scope="row">
              <label for="label5">부서</label>
            </th>
            <td colspan="1" class="clearfix">
              {{ agentState.deptNmPath }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label5">근무일</label>
            </th>
            <td colspan="1" class="clearfix">{{ agentState.workDt }} ({{ agentState.workDtDayStr }})</td>
            <th scope="row">
              <label for="label5">일반근무</label>
            </th>
            <td colspan="1" class="clearfix" v-if="agentState.workBasicNm">
              {{ agentState.workBasicNm }}/ {{ agentState.workBasicStartTime }} ~ {{ agentState.workBasicEndTime }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label5">시간외근무</label>
            </th>
            <td colspan="1" class="clearfix" v-if="agentState.workOverTimeNm">
              {{ agentState.workOverTimeNm }}/ {{ agentState.workOverTimeStartTime }} ~
              {{ agentState.workOverTimeEndTime }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="height: auto; min-height: 400px">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
    <div class="page-sub-box mt-3">
      <div class="bottom-btn-wrap text-center">
        <DxButton
          text="이 전"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="onPagePrev"
          :visible="button.prev.visible"
        />
        <DxButton text="등 록" :width="120" :height="40" class="default filled txt_S medium" :use-submit-behavior="true" @click="onSave" />
        <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { DxButton } from 'devextreme-vue/button';

  import { isSuccess } from '@/plugins/common-lib';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxSelectBox, DxRequiredRule, DxValidator, DxButton, Tab, Tabs },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-worktype',
          nextPageName: '',
          prevPageName: 'modal-page-select-agent',
          agentData: {},
          workCategory: {},
        }),
      },
    },
    data() {
      return {
        codeMap: {},
        isShowPage: false,
        hiddenHeader: false,
        button: {
          prev: {
            visible: false,
          },
        },
        dataGrid: {
          keyExpr: 'workTypeId', // 그리드 키값
          refName: 'workTypeGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: true, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          height: '490', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'virtual', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: false, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'single', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          columns: [
            {
              caption: '근무명',
              dataType: 'string',
              dataField: 'workNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'left',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              caption: '월',
              dataField: 'mondayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '화',
              dataField: 'tuesdayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '수',
              dataField: 'wednesdayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '목',
              dataField: 'thursdayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '금',
              dataField: 'fridayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '토',
              dataField: 'saturdayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '일',
              dataField: 'sundayWorkCd',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '근무시간',
              dataField: 'workTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
              calculateCellValue: e => {
                return `${e.workStartTime} ~ ${e.workEndTime}`;
              },
            },
          ], // 컬럼 정보
        },
        agentState: {
          agtNm: '',
          deptNmPath: '',
          workDt: '',
          workDtDayStr: '',
          workBasicNm: '',
          workBasicStartTime: '',
          workBasicEndTime: '',
          workOverTimeNm: '',
          workOverTimeStartTime: '',
          workOverTimeEndTime: '',
        },
      };
    },
    computed: {},
    methods: {
      onSave() {
        let workTypeIdList = this.$refs[this.dataGrid.refName].getGridInstance.getSelectedRowKeys();
        if (workTypeIdList.length === 0) {
          this.$_Toast('선택된 근무유형이 없습니다.');
          return;
        }

        this.appointmentData.workTypeId = Array.isArray(workTypeIdList) ? workTypeIdList[0] : workTypeIdList;
        this.$emit('save', this.appointmentData);
      },
      onPagePrev() {
        this.appointmentData.prevPageName = 'modal-page-select-agent';
        this.appointmentData.submitList = [];
        this.$emit('pagePrev', this.appointmentData);
      },
      onPageClose() {
        this.$emit('pageClose');
      },
      /**
       * @description : 요일 셀렉트 박스 템플릿
       * */
      dayCellTemplate(container, options) {
        let element = document.createElement('div');
        element.innerHTML = options.text;
        element.style.fontWeight = 'bold';

        if (options.text === this.$_enums.ewm.workStatus.WORK_DAY.label) {
          element.style.color = '#089027';
        } else if (options.text === this.$_enums.ewm.workStatus.DAY_OFF.label) {
          element.style.color = '#f7665f';
        }
        container.append(element);
      },
      async onSearch() {
        let vm = this;
        let excludeWorkTypeId = [];
        if (
          vm.appointmentData?.agentData?.workBasicId &&
          this.$_enums.ewm.workCategory.BASIC.value === vm.appointmentData.workCategory.value
        ) {
          excludeWorkTypeId.push(vm.appointmentData.agentData.workBasicId);
        } else if (
          vm.appointmentData?.agentData?.workOverTimeId &&
          this.$_enums.ewm.workCategory.OVER_TIME.value === vm.appointmentData.workCategory.value
        ) {
          excludeWorkTypeId.push(vm.appointmentData.agentData.workOverTimeId);
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'workTypeId',
          async load() {
            const payload = {
              actionname: 'EWM_WORK_TYPE_LIST',
              data: {
                workCategory: vm.appointmentData.workCategory.value,
              },
              useErrorPopup: true,
            };

            if (excludeWorkTypeId.length > 0) {
              payload.data.workTypeId = `<>${excludeWorkTypeId.join(',')}`;
            }

            if (vm.appointmentData?.agentData?.workDt) {
              payload.data.workDt = (vm.appointmentData?.agentData?.workDt).replace('-', '').replace('-', '');
            }

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
    },
    created() {},
    mounted() {
      this.onSearch();
      this.hiddenHeader =
        this.appointmentData.agentData === undefined || this.appointmentData.agentData === null || this.appointmentData.agentData === {};

      this.agentState.agtNm = this.appointmentData?.agentData?.agtNm;
      this.agentState.deptNmPath = this.appointmentData?.agentData?.deptNmPath;
      this.agentState.workDt = this.appointmentData?.agentData?.workDt;
      this.agentState.workDtDayStr = this.appointmentData?.agentData?.workDtDayStr;
      this.agentState.workBasicNm = this.appointmentData?.agentData?.workBasicNm;
      this.agentState.workBasicStartTime = this.appointmentData?.agentData?.workBasicStartTime;
      this.agentState.workBasicEndTime = this.appointmentData?.agentData?.workBasicEndTime;
      this.agentState.workOverTimeNm = this.appointmentData?.agentData?.workOverTimeNm;
      this.agentState.workOverTimeStartTime = this.appointmentData?.agentData?.workOverTimeStartTime;
      this.agentState.workOverTimeEndTime = this.appointmentData?.agentData?.workOverTimeEndTime;

      // 이전 페이지가 있을 경우 이전 버튼 활성화
      if (this.appointmentData.prevPageName !== undefined && this.appointmentData.prevPageName !== '') {
        this.button.prev.visible = true;
      }
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ecs-tab-box {
      margin: 0 5px;
    }
    .page-sub-box {
      padding: 5px;
    }
  }
</style>
